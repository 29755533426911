.results {
  @include media-breakpoint-up(md) {
    margin-left: 28px;
  }
}

.sort {
  @include media-breakpoint-up(md) {
    margin-right: 28px;
  }
}

.results-sort {
  margin-top: 24px;
}

.remove-right-margin {
  form {
    margin-right: 0 !important;
  }
}
