.home-video {
  position: relative;
  top: -84px;
  margin-bottom: -84px;
  height: 90vh;
  background-color: #000;

  article {
    position: absolute;
    top: 0;
    padding: 0;
  }

  img, video {
    filter: brightness(80%);
  }
}

.video-text {
  position: absolute;
  bottom: 18vh;

}
