.Pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-500-light);

    .tmt-large & {
      width: 52px;
      height: 52px;
    }

    &:hover,
    &:focus {
      &:not(.Pagination__arrow--inactive) {
        color: var(--color-primary-500-light);
      }
    }

    &--inactive {
      cursor: not-allowed !important;
    }
  }

  &__input {
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-neutral-300-light);
    border-radius: 4px;
    //@include responsive-type("baseline");
    //font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 0 18px 0 18px;
    padding-top: 5px;
    color: var(--color-neutral-1000-light);
    background-color: rgba(0, 0, 0, 0);

    &:hover,
    &:focus,
    &:active {
      &:not(:disabled) {
        border-color: var(--color-primary-500-light);
        color: var(--color-primary-500-light);
      }
    }
  }
}
