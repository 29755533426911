@use "sass:map";

$colors: (
  primary-50: (
    light: #FBF5F1
  ),
  primary-100: (
    light: #E3C7B5
  ),
  primary-500: (
    light: #AE6C41
  ),
  primary-700: (
    light: #774A2C
  ),
  primary-900: (
    light: #3C2516
  ),
  secondary-50: (
    light: #F8FBF7
  ),
  secondary-300: (
    light: #A0AB99
  ),
  secondary-500: (
    light: #84927F
  ),
  secondary-900: (
    light: #54574E
  ),
  tertiary-500: (
    light: #53C057
  ),
  tertiary-900: (
    light: #042205
  ),
  neutral-0: (
    light: #ffffff
  ),
  neutral-100: (
    light: #f5f5f5
  ),
  neutral-200: (
    light: #eeeeee
  ),
  neutral-300: (
    light: #e0e0e0
  ),
  neutral-400: (
    light: #bdbdbd
  ),
  neutral-500: (
    light: #757575
  ),
  neutral-600: (
    light: #6A6A6A
  ),
  neutral-700: (
    light: #616161
  ),
  neutral-800: (
    light: #424242
  ),
  neutral-900: (
    light: #212121
  ),
  neutral-1000: (
    light: #3C2516
  ),
  white: (
    light: #ffffff
  ),
  black: (
    light: #000000
  ),
  green-100: (
    light: #ecffed
  ),
  green-500: (
    light: #009d06
  ),
  orange-100: (
    light: #fff3e5
  ),
  orange-500: (
    light: #ec7f00
  ),
  red-100: (
    light: #fff2f2
  ),
  red-500: (
    light: #d20000
  ),
  red-700: (
    light: #8c0000
  ),
  red-800: (
    light: #5a0000
  )
);

$backdrops: (
  black-48: (
    light: rgba(0, 0, 0, 0.48),
    dark: rgba(0, 0, 0, 0.64)
  ),
  black-64: (
    light: rgba(0, 0, 0, 0.64),
    dark: rgba(0, 0, 0, 0.80)
  ),
  black-80: (
    light: rgba(0, 0, 0, 0.80),
    dark: rgba(0, 0, 0, 0.96)
  ),
  white-48: (
    light: rgba(1, 1, 1, 0.48),
    dark: rgba(1, 1, 1, 0.64)
  ),
  white-64: (
    light: rgba(1, 1, 1, 0.64),
    dark: rgba(1, 1, 1, 0.80)
  ),
  white-80: (
    light: rgba(1, 1, 1, 0.80),
    dark: rgba(1, 1, 1, 0.96)
  ),
  white-90: (
    light: rgba(1, 1, 1, 0.90),
    dark: rgba(1, 1, 1, 0.90)
  ),
  gradient: (
    light: linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.16) 83.59%),
    dark: linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.24) 83.59%)
  )
);

$shadows: (
  shadow-16: (
    light: 0px 4px 16px rgba(0, 0, 0, 0.16),
    dark: 0px 4px 16px rgba(0, 0, 0, 0.48)
  ),
  shadow-24: (
    light: 0px 4px 24px rgba(0, 0, 0, 0.16),
    dark: 0px 4px 24px rgba(0, 0, 0, 0.48)
  ),
  shadow-32: (
    light: 0px 8px 32px rgba(0, 0, 0, 0.16),
    dark: 0px 8px 32px rgba(0, 0, 0, 0.48)
  ),
  shadow-64: (
    light: 0px 8px 64px rgba(0, 0, 0, 0.16),
    dark: 0px 8px 64px rgba(0, 0, 0, 0.48)
  )
);

$bold-shadows: (
  bold-shadow-16: (
    light: 0px 4px 16px rgba(0, 0, 0, 0.32),
    dark: 0px 4px 16px rgba(0, 0, 0, 0.32)
  ),
  bold-shadow-24: (
    light: 0px 4px 24px rgba(0, 0, 0, 0.32),
    dark: 0px 4px 24px rgba(0, 0, 0, 0.72)
  ),
  bold-shadow-32: (
    light: 0px 8px 32px rgba(0, 0, 0, 0.32),
    dark: 0px 8px 32px rgba(0, 0, 0, 0.72)
  ),
  bold-shadow-64: (
    light: 0px 8px 64px rgba(0, 0, 0, 0.32),
    dark: 0px 8px 64px rgba(0, 0, 0, 0.72)
  )
);

$background-blurs: (
  background-blur-8: blur(4px),
  background-blur-12: blur(6px),
  background-blur-16: blur(8px),
  background-blur-24: blur(8px),
);

:root {
  @each $prop, $value in $colors {
    --color-#{"" + $prop}-light: #{map.get($value, light)};
    --color-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $backdrops {
    --backdrop-#{"" + $prop}-light: #{map.get($value, light)};
    --backdrop-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $shadows {
    --shadow-#{"" + $prop}-light: #{map.get($value, light)};
    --shadow-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $bold-shadows {
    --bold-shadow-#{"" + $prop}-light: #{map.get($value, light)};
    --bold-shadow-#{"" + $prop}-dark: #{map.get($value, dark)};
  }
}

// Create utility classes
@each $prop, $value in $colors {
  .color-#{"" + $prop}-light {
    color: #{map.get($value, light)} !important;
  }

  .color-#{"" + $prop}-dark {
    color: #{map.get($value, dark)} !important;
  }

  .bg-#{"" + $prop}-light {
    background-color: #{map.get($value, light)} !important;
  }

  .bg-#{"" + $prop}-dark {
    background-color: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $backdrops {
  .backdrop-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .backdrop-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $shadows {
  .shadow-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .shadow-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $bold-shadows {
  .bold-shadow-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .bold-shadow-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}
