@use "sass:map";

@font-face {
  font-family: 'Manrope';
  src: url('../../public/fonts/manrope-regular.woff2') format('woff2'),
       url('../../public/fonts/manrope-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../public/fonts/manrope-bold.woff2') format('woff2'),
       url('../../public/fonts/manrope-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

$main-font: "Manrope";

$sizes-list: (
  tiny: (
    default: (
      font-size: 12px,
      line-height: 16px
    ),
    large: (
      font-size: 14px,
      line-height: 20px
    )
  ),
  small: (
    default: (
      font-size: 14px,
      line-height: 20px
    ),
    large: (
      font-size: 16px,
      line-height: 24px
    )
  ),
  baseline: (
    default: (
      font-size: 16px,
      line-height: 24px
    ),
    large: (
      font-size: 20px,
      line-height: 28px
    )
  ),
  xs: (
    default: (
      font-size: 20px,
      line-height: 28px
    ),
    large: (
      font-size: 24px,
      line-height: 32px
    )
  ),
  sm: (
    default: (
      font-size: 24px,
      line-height: 32px
    ),
    large: (
      font-size: 32px,
      line-height: 40px
    )
  ),
  md: (
    default: (
      font-size: 32px,
      line-height: 40px
    ),
    large: (
      font-size: 40px,
      line-height: 48px
    )
  ),
  lg: (
    default: (
      font-size: 40px,
      line-height: 48px
    ),
    large: (
      font-size: 52px,
      line-height: 64px
    )
  ),
  xl: (
    default: (
      font-size: 52px,
      line-height: 64px
    ),
    large: (
      font-size: 64px,
      line-height: 72px
    )
  ),
  xxl: (
    default: (
      font-size: 64px,
      line-height: 72px
    ),
    large: (
      font-size: 72px,
      line-height: 80px
    )
  )
);

@mixin get-size($size, $scale) {
  font-size: map.get($sizes-list, $size, $scale, "font-size");
  line-height: map.get($sizes-list, $size, $scale, "line-height");
}

@mixin get-responsive-size($size, $scale) {
  @if $size == "md" {
    @include get-size("md", $scale);

    @include media-breakpoint-down(md) {
      @include get-size("sm", $scale);
    }
  } @else if $size == "lg" {
    @include get-size("lg", $scale);

    @include media-breakpoint-down(md) {
      @include get-size("md", $scale);
    }
  } @else if $size == "xl" {
    @include get-size("xl", $scale);

    @include media-breakpoint-down(md) {
      @include get-size("lg", $scale);
    }
  } @else if $size == "xxl" {
    @include get-size("xxl", $scale);

    @include media-breakpoint-down(md) {
      @include get-size("lg", $scale);
    }
  } @else {
    @include get-size($size, $scale);
  }
}

@mixin responsive-type($size, $scale: "default", $strong: false) {
  font-family: $main-font;
  letter-spacing: 0;

  @if $strong == true {
    font-weight: bold;
  } @else {
    font-weight: normal;
  }

  @include get-responsive-size($size, $scale);
}

@mixin type($size, $scale: "default", $strong: false) {
  font-family: $main-font;
  letter-spacing: 0;

  @if $strong == true {
    font-weight: bold;
  } @else {
    font-weight: normal;
  }

  @include get-size($size, $scale);
}

@mixin main-font {
  font-family: $main-font;
}

:root {
  --main-font: $main-font;
}

// Create utility classes
@each $prop, $value in $sizes-list {
  .type-#{$prop} {
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0;
    @include get-size($prop, "default");
  }

  .tmt-large .type-#{$prop},
  .type-#{$prop}-large {
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0;
    @include get-size($prop, "large");
  }

  .type-#{$prop}-strong {
    font-family: $main-font;
    font-weight: bold;
    letter-spacing: 0;
    @include get-size($prop, "default");
  }

  .tmt-large .type-#{$prop}-strong
  .type-#{$prop}-large-strong {
    font-family: $main-font;
    font-weight: bold;
    letter-spacing: 0;
    @include get-size($prop, "large");
  }

  .responsive-type-#{$prop} {
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0;
    @include get-responsive-size($prop, "default");
  }

  .tmt-large .responsive-type-#{$prop},
  .responsive-type-#{$prop}-large {
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0;
    @include get-responsive-size($prop, "large");
  }

  .responsive-type-#{$prop}-strong {
    font-family: $main-font;
    font-weight: bold;
    letter-spacing: 0;
    @include get-responsive-size($prop, "default");
  }

  .tmt-large .responsive-type-#{$prop}-strong,
  .responsive-type-#{$prop}-large-strong {
    font-family: $main-font;
    font-weight: bold;
    letter-spacing: 0;
    @include get-responsive-size($prop, "large");
  }
};

