.banners {
  flex-wrap: wrap;
  color: var(--color-white-light);

  .banner1 {
    background-color: var(--color-secondary-500-light);
  }

  .banner2 {
    background-color: #8b9883;
  }

  .banner1,
  .banner2 {
    padding: 0;
    padding: var(--space-xl) 0 var(--space-sm) 0;
  }

  .banner-title {
    @include responsive-type("md", "default", true);
    padding: 0 var(--space-md) var(--space-lg) var(--space-md);
  }

  .image-container {
    display: flex;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
      &.grow {
        transition: all 1s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
