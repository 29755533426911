.PageHeader {
  position: relative;

  &--fixedHeight {
    min-height: var(--height);
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.64) 100%
      );
    }
  }

  &__body {
    .Card {
      border: none;

      .CardTitle__title {
        @include responsive-type(md, "default", true);

        .PageHeader--light & {
          color: var(--color-white-light);
        }
      }

      .CardBody {
        padding: 0;

        & > * {
          margin-bottom: var(--space-sm);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .PageHeader--light & {
          color: var(--color-white-light);
        }
      }

      .tmt-large &,
      .PageHeader--large & {
        .CardBody > * {
          margin-bottom: var(--space-sm-large);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .CardTitle__title {
          @include responsive-type(md, "large", true);
        }
      }
    }
  }

  &__nav {
    margin-bottom: var(--space-md);

    .tmt-large &,
    .PageHeader--large & {
      margin-bottom: var(--space-md-large);
    }
  }
}

.LeftAlign {
  text-align: left;
  display: flex;

  .CardBody {
    text-align: left;
    align-items: flex-start;
    margin-left: var(--space-md);
  }

  @include media-breakpoint-down(sm) {
    display: block;

    .CardBody {
      margin-left: 0;
    }
  }
}

.Center {
  display: flex;
  flex-direction: column;
  align-items: center;
  .CardBody {
    align-items: center;
    text-align: center;
  }
}

.PageHeader__icon {
  position: relative;
  z-index: 5;
}

@include media-breakpoint-down(sm) {
  .PageHeader__icon
   svg {
    width: 128px;
    height: 128px;
  }
}
