.Accordion {

  &__item {

    & + & {
      margin-top: var(--space-xs);

      .tmt-large &,
      .Accordion--large & {
        margin-top: var(--space-xs-large);
      }
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .Button--tertiary {
      margin-top: var(--space-xxs);
    }

    &-header {

      button {
        @include responsive-type("baseline", "default", true);
        margin-bottom: var(--space-xxs);

        .tmt-large &,
        .Accordion--large & {
          @include responsive-type("baseline", "large", true);
          margin-bottom: var(--space-xxs-large);
        }
        .Button--tertiary {
          margin-top: var(--space-xxs-large);
        }
      }
    }
  }
}
