.rich-text {
  & > * {
    margin-bottom: var(--space-ms);

    .tmt-large & {
      margin-bottom: var(--space-ms-large);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  }