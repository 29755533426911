.ImageGalleryItem {
  position: relative;
  display: inline-block;
  width: 100%;

  &.nocaption:after {
    content: "";
    position: absolute;
    left: 0px;
    padding-top: 14px;
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: var(--border-radius-8);
  }

  &__caption {
    display: inline-block;
    width: 100%;
    margin-top: var(--space-sm);
    overflow-wrap: break-word;
    @include responsive-type("small");

    .tmt-large,
    .ImageGallery--large &,
    .ImageGalleryItem--large & {
      @include responsive-type("small", "large");
    }
  }

  &__caption::after {
    content: "";
    position: absolute;
    left: 0px;
    padding-top: 25px;
    width: 100%;
  }
}


.PageHeader {
  .Carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    .Carousel__wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      .Carousel__slide {
        width: 100%;
        height: 100%;
        padding: 0;
        .Carousel__title {
          width: 100%;
          height: 100%;
          padding: 0;
          .ImageGalleryItem {
            width: 100%;
            height: 100%;
            padding: 0;
            .ImageGalleryItemCarousel {
              width: 100%;
              height: 100%;
              .PageHeader__cover {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.alignItemsLeft {
  .Carousel__nav {
    position: absolute;
    bottom: 5%;
  }
  .Carousel__nav-prev {
    padding-left: var(--space-md);
  }
  .Carousel__nav-next {
    padding-left: var(--space-sm);
  }
}

.alignItemsCenter {
  .Carousel__nav{
    top: 42%;
  }
  .Carousel__nav-prev {
    position: absolute;
    left: 1%;
  }
  .Carousel__nav-next {
    position: absolute;
    right: 1%;
  }
}
