table {
  color: var(--color-neutral-1000-light);
  @include responsive-type("baseline");

  .tmt-large & {
    @include responsive-type("baseline", "large");
  }

  tr {
    border-bottom: 1px solid var(--color-neutral-300-light);
  }

  td {
    vertical-align: bottom;
  }

  th {
    @include responsive-type("baseline", "default", true);

    .tmt-large & {
      @include responsive-type("baseline", "large", true);
    }
  }
}
