.CardBody {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--space-sm);
  color: var(--color-neutral-1000-light);
  @include responsive-type(baseline, "default", false);

  & > * {
    margin-bottom: var(--space-xs);


    &:last-child {
      margin-bottom: 0;
    }
  }

  &__links {
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--space-sm);
  }

  &--large,
  .tmt-large &,
  .Card--large & {
    padding: var(--space-sm-large);
    @include responsive-type(baseline, "large", false);

    .CardBody__links {
      column-gap: var(--space-sm-large);
    }
  }


  &--large > *,
  .tmt-large & > *,
  .Card--large & > * {
    margin-bottom: var(--space-xs-large);
  }
}
