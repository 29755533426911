.filters {
  width: 100%;

#mobile-btn-container{
  .Button__icon {
    &::before {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      margin-top: 1px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C12.5523 2 13 2.44772 13 3V11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H11V3C11 2.44772 11.4477 2 12 2Z' fill='%23AE6C41'/%3E%3C/svg%3E");
    }
  }
}
#mobile-btn-container.active{
  .Button__icon {
    &::before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z' fill='%23AE6C41'/%3E%3C/svg%3E%0A");    }
  }
}
  .mobile-btn {
    display: none;
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--color-primary-500-light);
    &::before {
      background-color: transparent !important;
    }
  }
  .dropdown-options {
    display: flex;
    width: 100%;
  }
  label {
    margin-right: var(--space-md);
    display: block;
  }
  @include media-breakpoint-down(md) {
    .search-input {
      margin-bottom: var(--space-sm);
    }
    .mobile-btn {
      display: flex;
    }
    label {
      display: none;
    }
    flex-direction: column;
  }
  .dropdown-options {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .dropdown-options {
    margin-right: var(--space-sm);
    &:last-of-type {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    label {
      margin-right: 0;
    }
    .active {
      display: block !important;
    }
  }

  .search-input {
    width: 100%;
    margin-right: var(--space-sm);
  }

  .non-pt-filters {
    .choices {
      flex-grow: 1;
    }
  }

  #mobile-btn-container.active {
    margin-bottom: var(--space-sm);
  }
}
