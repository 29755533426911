.PageHeader {
  display: flex;

  .PageHeader__body {
    @include media-breakpoint-down(md) {
      margin-bottom: var(--space-md);
    }
  }

  .image-half-width {
    img {
      border-radius: var(--border-radius-8);
    }
  }

  .CardBody {
    border: none;
  }
  
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.PageHeader.centered {
  justify-content: center;
  align-items: center;
}

.PageHeader--carousel.centered {
  .Carousel__pagination {
    display: block;
  }
  .PageHeader__body {
    padding: 310px 0 136px 0;
  }
  @include media-breakpoint-down(sm) {
    .Carousel__pagination {
      display: none;
    }
    .Carousel__nav {
      top: 85%;
    }
    .Carousel__nav-prev {
      position: absolute;
      left: 30%;
    }
    .Carousel__nav-next {
      position: absolute;
      right: 30%;
    }
  }
}

.PageHeader--carousel.leftaligned {
  .PageHeader__body {
    padding-bottom: 632px;
  }
  @include media-breakpoint-down(sm) {
    .PageHeader__body {
      padding-bottom: 440px;
    }
  }
}

.PageHeader__body.LeftAlign {
  padding-left: var(--space-md);
}


.bgd-dark {
  background-color: var(--color-black-light);
}