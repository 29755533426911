@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';
@import '../variables/grid'; // Override default values
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/mixins/breakpoints';
@import './bootstrap4-reboot.min'; // Use v4 reboot
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/tables';
@import '../variables/spacing'; // Override default values
@import 'bootstrap/scss/utilities/api';
@import '../variables/accordion'; // Override default values
@import 'bootstrap/scss/accordion';
@import '../variables/tooltip'; // Override default values
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/transitions';
.container,
.container-fluid,
.row {
  --bs-gutter-x: 16px;
  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 32px;
  }
}
