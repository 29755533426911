.Fact {
  position: relative;
  min-width: 182px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-neutral-1000-light);

  &__icon {
    color: var(--color-neutral-500-light);
    margin-bottom: var(--space-sm);

    .Fact--large &,
    .tmt-large & {
      margin-bottom: var(--space-sm-large);
    }
  }

  &__title {
    @include responsive-type("small");

    .Fact--large &,
    .tmt-large & {
      @include responsive-type("small", "large");
    }
  }

  &__subtitle {
    @include responsive-type("baseline", "default", true);

   .Fact--large &,
   .tmt-large & {
      @include responsive-type("baseline", "large", true);
    }
  }

  &__circle {
    position: relative;
    width: var(--space-xxl);
    height: var(--space-xxl);
    margin-bottom: var(--space-sm);
    @include responsive-type("xs", "default", true);

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-1000-light);
    border: 4px solid var(--color-neutral-400-light);
    border-radius: 50%;

    .Fact--large &,
    .tmt-large & {
      width: var(--space-xxxl);
      height: var(--space-xxxl);
      margin-bottom: var(--space-sm-large);
      @include responsive-type("xs", "large", true);
    }

    .Fact--animate & {
      border: none;
    }

    &--radial {
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      transform: rotate(-90deg);

      svg {
        fill: none;

        circle {
          stroke: var(--color-neutral-400-light);
        }
      }
    }
  }
}
