.arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-500-light) !important;

  .tmt-large & {
    width: 52px;
    height: 52px;
  }

  &:hover,
  &:focus {
    &:not(.Pagination__arrow--inactive) {
      color: var(--color-primary-700-light);
    }
  }
}

.Pagination {
  .arrow {
    color: var(--color-primary-500-light);

    &:hover,
    &:focus {
      &:not(.Pagination__arrow--inactive) {
        color: var(--color-primary-500-light);
      }
    }
  }
}
