.CardTitle {
  position: relative;
  color: var(--color-neutral-1000-light);

  &__title {
    @include responsive-type(xs, "default", true);
  }

  &__subtitle {
    @include responsive-type(baseline, "default", true);
    margin-top: var(--space-tiny);
    color: var(--color-neutral-600-light);
  }

  &--large,
  .tmt-large &,
  .Card--large & {

    .CardTitle__title {
      @include responsive-type(xs, "large", true);
    }

    .CardTitle__subtitle {
      @include responsive-type(baseline, "large", true);
      margin-top: var(--space-tiny-large);
    }
  }
}
