#menu {
  position: relative;
  z-index: 2;
  height: 84px;
}

.navbar {
  height: 84px;

  .first-block,
  .navbar-logo {
    display: flex;
    align-items: flex-end;
  }

.second-block-white {
  width: 100%;
  border-bottom: 4px solid var(--color-white-light);
}

.second-block {
  width: 100%;
  border-bottom: 4px solid var(--color-primary-500-light);
}



  .navbar-logo-link {
    z-index: 10;
  }

  .PT-button {
    position: absolute;
    right: 1px;
  }
}

.menu-items {
  display: flex;
  position: relative;
  top: -40px;

  @media (max-width: 920px) {
    display: none;
  }

  .menu-item-underline {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-weight: 600;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 4px;
      bottom: -12px;
      left: 0;
      background-color: var(--color-primary-500-light);
      transition: transform 0.25s ease-out;
    }
    &:hover:after,
    &:focus:after,
    &:active:after {
      transform: scaleX(1);
    }
  }

  .menu-item-underline.active {
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(1);
      height: 4px;
      bottom: -12px;
      left: 0;
    }
  }
}

#mobile-menu-btn {
  position: relative;
  padding-right: 40px;
}

#lang-btn {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-md);
  @media (max-width: 920px) {
    right: 10px;
  }
}

#mobile-menu-btn,
#lang-btn {
  display: inline-block;
  background: none;
  appearance: none;
  border: none;
  padding-left: 0;
  cursor: pointer;
  font-weight: 600;
}

.menu-dropdown {
  display: flex;
  position: relative;
  top: -40px;
  @media (min-width: 920px) {
    display: none;
  }
}

#mobile-menu {
  position:relative;
  z-index: 10;
  display: none;
  height: 150vh;
  gap: 32px;
  @media (min-width: 920px) {
    display: none !important;
  }
}

.margin-right56 {
  margin-right: 56px;
  @include media-breakpoint-down(lg) {
    margin-right: 0;
  }
}

#mobile-menu.active {
  display: block;
  body {
    position: fixed!important;
  }
  .mobile-menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: var(--space-xl-large);
  }
}

.white-menu {
  color: var(--color-white-light);

  .menu-item-underline {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 4px;
      bottom: -12px;
      left: 0;
      background-color: var(--color-white-light);
      transition: transform 0.25s ease-out;
    }
  }
}

.menu-item-underline {
  font-weight: 600;
  &:hover {
    font-weight: 600;
  }
}
