.footer {
    @include main-font;

    &__logos {
        align-items: center;
        gap: 55px;
        padding-bottom: var(--space-lg);
        @include media-breakpoint-down(md) {
            padding-bottom: var(--space-md);
        }
    }

    &__container {
        border: 1px solid var(--color-neutral-300-light);
        border-radius: var(--border-radius-8);
        padding: var(--space-sm);
        @include media-breakpoint-down(md) {
        margin: var(--space-sm) 0 var(--space-sm) 0;
          }
    }

    .copyright-text {
        font-size: 12px;
        line-height: 16px;
    }
}

.divider {
    display: flex;
    gap: var(--space-xxs);
    border-bottom: 1px solid var(--color-neutral-300-light);
    padding: var(--space-xxs) 0 var(--space-xxs) 0;
}



.margin-right18 {
    margin-right: 18px;
}

.espa-banner {
    position: fixed;
    bottom: 0;
    right: -1px;
    width: 340px;
    z-index: 10;
    opacity: 1;
    -webkit-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;

    @include media-breakpoint-down(lg) {
        .espa-banner {
            width: 250px;
        }
    }

    @include media-breakpoint-down(md) {
        .espa-banner {
            width: 200px;
        }
    }

    img {
        max-width: 100%;
        display: block;
    }
}

.espa-banner.hide-banner {
    opacity: 0;
}

div.footer-espa-banner img {
    max-width: 340px;
    width: 100%;
    margin-bottom: var(--space-sm);
}