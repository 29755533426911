.feedback {
  display: flex;
  padding: 20px 16px 20px 16px;
  &-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .feedback-text {
    margin-left: 12px;
  }
  &--informational {
    border: 1px solid var(--color-primary-500-light);
    > svg {
      color: var(--color-primary-500-light);
    }
  }
  &--confirmation {
    border: 1px solid var(--color-green-500-light);
    > svg {
      color: var(--color-green-500-light);
    }
  }
  &--warning {
    border: 1px solid var(--color-orange-500-light);
    > svg {
      color: var(--color-orange-500-light);
    }
  }
  &--error {
    border: 1px solid var(--color-red-500-light);
    > svg {
      color: var(--color-red-500-light);
    }
  }
}

.layer {
  &.feedback--informational {
    background-color: var(--color-primary-100-light);
    .feedback-text {
      color: var(--color-primary-500-light);
    }
  }
  &.feedback--confirmation {
    background-color: var(--color-green-100-light);
    .feedback-text {
      color: var(--color-green-500-light);
    }
  }
  &.feedback--warning {
    background-color: var(--color-orange-100-light);
    .feedback-text {
      color: var(--color-orange-500-light);
    }
  }
  &.feedback--error {
    background-color: var(--color-red-100-light);
    .feedback-text {
      color: var(--color-red-500-light);
    }
  }
}
