@import 'bootstrap/scss/utilities';

$defaultSpacers: (
  zero: 0,
  tiny: 4px,
  xxs: 8px,
  xs: 12px,
  sm: 16px,
  ms: 24px,
  md: 32px,
  lg: 48px,
  xl: 64px,
  xxl: 96px,
  xxxl: 128px
);

$largeSpacers: (
  zero: 0,
  tiny: 8px,
  xxs: 12px,
  xs: 16px,
  sm: 24px,
  ms: 32px,
  md: 48px,
  lg: 64px,
  xl: 72px,
  xxl: 96px,
  xxxl: 128px
);

$utilities: map-merge(
  $utilities,
  (
    "margin": (
      responsive: true,
      property: margin,
      class: m,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-large": (
      responsive: true,
      property: margin,
      class: 'tmt-large .m',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-x-large": (
      responsive: true,
      property: margin-right margin-left,
      class: 'tmt-large .mx',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-y-large": (
      responsive: true,
      property: margin-top margin-bottom,
      class: 'tmt-large .my',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-top-large": (
      responsive: true,
      property: margin-top,
      class: 'tmt-large .mt',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-end": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-end-large": (
      responsive: true,
      property: margin-right,
      class: 'tmt-large .mr',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-bottom-large": (
      responsive: true,
      property: margin-bottom,
      class: 'tmt-large .mb',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    "margin-start": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: map-merge($defaultSpacers, (auto: auto))
    ),
    "margin-start-large": (
      responsive: true,
      property: margin-left,
      class: 'tmt-large .ml',
      values: map-merge($largeSpacers, (auto: auto))
    ),
    // Padding utilities
    "padding": (
      responsive: true,
      property: padding,
      class: p,
      values: $defaultSpacers
    ),
    "padding-large": (
      responsive: true,
      property: padding,
      class: 'tmt-large .p',
      values: $largeSpacers
    ),
    "padding-x": (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $defaultSpacers
    ),
    "padding-x-large": (
      responsive: true,
      property: padding-right padding-left,
      class: 'tmt-large .px',
      values: $largeSpacers
    ),
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $defaultSpacers
    ),
    "padding-y-large": (
      responsive: true,
      property: padding-top padding-bottom,
      class: 'tmt-large .py',
      values: $largeSpacers
    ),
    "padding-top": (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $defaultSpacers
    ),
    "padding-top-large": (
      responsive: true,
      property: padding-top,
      class: 'tmt-large .pt',
      values: $largeSpacers
    ),
    "padding-end": (
      responsive: true,
      property: padding-right,
      class: pr,
      values: $defaultSpacers
    ),
    "padding-end-large": (
      responsive: true,
      property: padding-right,
      class: 'tmt-large .pr',
      values: $largeSpacers
    ),
    "padding-bottom": (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $defaultSpacers
    ),
    "padding-bottom-large": (
      responsive: true,
      property: padding-bottom,
      class: 'tmt-large .pb',
      values: $largeSpacers
    ),
    "padding-start": (
      responsive: true,
      property: padding-left,
      class: pl,
      values: $defaultSpacers
    )
  )
);

:root {
  @each $prop, $value in $defaultSpacers {
    --space-#{$prop}: #{$value}
  }

  @each $prop, $value in $largeSpacers {
    --space-#{$prop}-large: #{$value}
  }
}
