$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1820px
);
