@use "sass:map";

$button-transition: .3s ease-out;

.Button {
  position: relative;
  appearance: none;
  border: none;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: var(--space-xxs);
  padding: var(--space-xxs) var(--space-sm);
  background: var(--color-primary-500-light);
  color: var(--color-white-light);
  border-radius: var(--border-radius-4);
  transition: color $button-transition,
              border-color $button-transition,
              background $button-transition;

  &:focus,
  &:hover {
    cursor: pointer;
    background: var(--color-primary-700-light);
    color: var(--color-white-light);
  }

  &:active {
    background: var(--color-primary-900-light);
  }

  &:disabled {
    cursor: not-allowed !important;
    background: var(--color-neutral-400-light) !important;
  }

  &--destructive {
    background: var(--color-red-500-light);

    &:focus,
    &:hover {
      cursor: pointer;
      background: var(--color-red-700-light);
    }

    &:active {
      background: var(--color-red-800-light);
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    background: var(--color-neutral-400-light);
  }

  &--secondary {
    border: 1px solid var(--color-primary-500-light);
    background: none;
    color: var(--color-primary-500-light);

    &:focus,
    &:hover {
      cursor: pointer;
      color: var(--color-white-light);
      background: var(--color-primary-700-light);
    }

    &:active {
      color: var(--color-white-light);
      background: var(--color-primary-900-light);
    }

    &:disabled {
      cursor: not-allowed !important;
      background: none !important;
      border: 1px solid var(--color-neutral-400-light) !important;
      color: var(--color-neutral-400-light) !important;
    }

    &.Button--destructive {
      color: var(--color-red-500-light);
      background: none;
      border: 1px solid var(--color-red-500-light);

      &:active,
      &:focus,
      &:hover {
        cursor: pointer;
        color: var(--color-white-light);
        background: var(--color-red-700-light);
        border: 1px solid var(--color-red-700-light);
      }
    }
  }

  &--action {
    background: none;
    appearance: none;
    border: none;
    color: var(--color-primary-500-light);
    &:hover{
      border: 1px solid var(--color-primary-500-light);
      background-color: transparent;
      color: var(--color-primary-500-light);
    }
    &:focus {
      border: 1px solid var(--color-primary-500-light);
      background-color: var(--color-primary-100-light);
      color: var(--color-primary-500-light);
    }
    &:active {
      border: 1px solid var(--color-primary-500-light);
      background-color: var(--color-primary-500-light);
      color:var(--color-white-light);
    }
  }

  &--tertiary,
  &--tertiary-alt {
    padding: 0;
    background: none;
    color: var(--color-primary-700-light);

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: var(--border-radius-8);
      background: var(--color-neutral-300-light);
      opacity: 0;
      transition: opacity $button-transition,
                  background $button-transition;
    }

    &:active:not(:disabled),
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background: none;

      &::before {
        opacity: 1;
      }
    }

    &:active {
      &::before {
        background: var(--color-neutral-400-light);
      }
    }

    &:disabled {
      color: var(--color-neutral-400-light) !important;
      background: none !important;
    }
  }

  &--tertiary {
    &:hover, &:focus {
      color: var(--color-primary-700-light);
    }
    &:active {
      color: var(--color-primary-900-light);
    }
  }

  &--tertiary-alt {
    color: var(--color-white-light);

    &::before {
      background: var(--color-primary-700-light);
    }

    &:active {
      &::before {
        background: var(--color-primary-900-light);
      }
    }
  }

  .tmt-large &,
  &--large {
    grid-column-gap: var(--space-xxs-large);
    padding: var(--space-xxs-large) var(--space-sm-large);

    .Button__label {
      @include responsive-type(baseline, "large", true);
    }

    &.Button--tertiary,
    &.Button--tertiary-alt {
      padding: 0;
    }
  }

  &__icon {
    z-index: 0;

    &--right {
      order: 2;
    }
  }

  &__label {
    @include responsive-type(baseline, "default", true);
    transform: translateY(1px); // Hack to align font metrics with icon

    .Button--icon-only & {
      display: none;
    }
  }
}

.Button--secondary {
  &.Button--disabled {
    border: 1px solid var(--color-neutral-400-light) !important;
    color: var(--color-neutral-400-light) !important;
  }
}

.Button--tertiary {
  &.Button--disabled {
    color: var(--color-neutral-400-light) !important;
  }
}