.facts-block {
    padding: var(--space-ms) var(--space-md) var(--space-ms) var(--space-md);
    @include media-breakpoint-down(lg) {
        padding-bottom: 0;
      }
    .Fact {
        color: var(--color-white-light);
        text-align: center;
        @include media-breakpoint-down(lg) {
            padding-bottom: var(--space-md);
          }
    }
}