$radii: (
  0: 0,
  2: 2px,
  4: 4px,
  8: 8px,
  16: 16px,
  32: 32px
);

:root {
  @each $prop, $value in $radii {
    --border-radius-#{$prop}: #{$value}
  }
}

@each $prop, $value in $radii {
  .border-radius-#{$prop} {
    border-radius: #{$value} !important;
  }
}

:root {
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E0E0E0;
  --bs-white-rgb: white;
}
