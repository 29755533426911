@use "sass:map";

$button-transition: 0.3s ease-out;

.PT-button {
  display: inline-block;
  position: relative;
  background: none;
  appearance: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-black-dark);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after,
  &:focus:after,
  &:active:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .tmt-large &,
  &--large {

    .PT-button__label {
      @include responsive-type(baseline, "large", true);
    }
  }

  &__label {
    @include responsive-type(baseline, "default", true);
    transform: translateY(1px); // Hack to align font metrics with icon
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  svg {
    transform: rotate(90deg);
    margin-bottom: 4px;
  }
}

.PT-button__dropdown {
  @include media-breakpoint-up(md) {
    display: none;
  }
  .choices__button {
    display: none;
  }
}

.PT-menu-buttons {
  @include media-breakpoint-down(md) {
    display: none;
  }
  .PT-button {
    margin-right: var(--space-ms);
  }
}

.PT-button.active {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: var(--color-black-dark);
  &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(1);
      transform-origin: bottom left;
      height: 2px;
      bottom: 0;
      left: 0;
  }
}

.PT-container {
  .choices {
    border: none;
    display: inline;
  }
  .choices[data-type*="select-one"]::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 19.0914V4.90857C7 4.10191 7.90598 3.62722 8.56921 4.08638L18.8124 11.1778C19.3868 11.5755 19.3868 12.4245 18.8124 12.8222L8.56921 19.9136C7.90598 20.3728 7 19.8981 7 19.0914Z' fill='currentColor'/%3E%3C/svg%3E");
    transform: rotate(90deg);
    top: 20%;
    right: 0;
  }
  .choices.is-open[data-type*="select-one"]::after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 19.0914V4.90857C7 4.10191 7.90598 3.62722 8.56921 4.08638L18.8124 11.1778C19.3868 11.5755 19.3868 12.4245 18.8124 12.8222L8.56921 19.9136C7.90598 20.3728 7 19.8981 7 19.0914Z' fill='currentColor'/%3E%3C/svg%3E");
    transform: rotate(90deg);
    margin-bottom: 4px;
    right: 0;
  }
  .choices__inner, .choices[data-type*="select-one"] .choices__input, .choices__list--dropdown, .choices__list[aria-expanded] {
    width: auto;
  }
  .choices__list--single {
    width: auto;
    .choices__list--single .choices__item {
      width: 100%;
    }
  }
  .choices__inner {
    padding-left: 0;
  }
  .choices__placeholder {
    opacity: 1;
  }
  .choices__list {
    white-space: nowrap;
  }
}


