@import "title";
@import "elements";

.Card {
  position: relative;
  display: flex;
  flex-direction: column;

  .CardBody {
    flex-grow: 1;
    border: 1px solid var(--color-neutral-300-light);
    border-radius: var(--border-radius-8);
  }

  &__image {
    position: relative;
    margin: 0 0 var(--space-sm) 0;

    img {
      max-width: 100%;
      border-radius: var(--border-radius-8);
    }
  }

  &--text-on-image {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--backdrop-gradient-light);
    }

    .CardBody {
      position: absolute;
      color: var(--color-neutral-0-light);
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: none;
    }
  }

  &--text-on-image {
    .CardTitle {
      color: var(--color-neutral-0-light);
    }
  }

  &--horizontal {
    flex-direction: row;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    .Card__image {
      margin: 0 var(--space-sm) 0 0;
      width: 300px;

      img, .AspectRatioBox {
        width: 300px;
      }
      @include media-breakpoint-down(lg) {
        order: 1;
        margin: 0 0 var(--space-sm) 0;
        width: auto;
        img, .AspectRatioBox {
          width: 100%;
        }
      }
    }
    .CardBody {
      flex-grow: 1;
      @include media-breakpoint-down(lg) {
        order: 2;
      }
    }
  }

  &--profile {
    text-align: center;

    .CardBody {
      align-items: center;
    }

    .Card__image {
      margin: var(--space-lg);
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.single-card {
  width: 50%!important;
  @include media-breakpoint-down(lg) {
    width: 100%!important;
  }
  .SingleAspectRatioBox {
    width: 100%!important;
    img {
      width: 100%;
    }
  }
}
