html,
body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}


@media (min-width: 920px) {

  body.home > header {background-color:#000000;}

}


.noscroll {
  overflow: hidden;
}

*, html {
  scroll-behavior: smooth !important;
}


body {
  margin: 0;
  padding: 0;
  color: var(--color-primary-900-light);
  @include main-font;
  @include responsive-type("baseline");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

header, main, footer {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.heading2Style {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.heading3Style {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.heading4Style {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

a {
  color: inherit;
  font-weight: normal;

  &:visited,
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
    font-weight: normal;
  }
}

body.home {
  #lang-btn:visited {
    color: var(--color-white-light);
  }
}


input,
textarea,
select,
button {
  color: inherit;
}

input,
button {
  &:focus {
    outline: none;
  }
}

label {
  display: block;
  @include responsive-type("tiny");
  color: var(--color-neutral-500-light);
  margin-bottom: var(--space-tiny);

  .tmt-large & {
    @include responsive-type("tiny", "large");
  }
}

.container-fluid {
  border-bottom: 1px solid var(--color-neutral-300-light);
}

@for $i from 3 through 10 {
  .temp-presentation-grid-x#{$i} {
    display: inline-grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-rows: repeat(#{$i}, auto);
    grid-gap: 32px;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.choices__list::-webkit-scrollbar{
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.choices__list::-webkit-scrollbar-track{
  background-color: #fff;
}

.choices__list::-webkit-scrollbar-track:hover{
  background-color: #f4f4f4;
}

/* scrollbar itself */
.choices__list::-webkit-scrollbar-thumb{
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}

.choices__list::-webkit-scrollbar-thumb:hover{
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.choices__list::-webkit-scrollbar-button{
  display: none;
}

.tooltip {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));

  &-inner {
    @include responsive-type("small");

    .tmt-large & {
      @include responsive-type("small", "large");
    }
  }
}

.bg-white {
  background-color: white !important;
}

.text-white {
  color: white !important;
}

.border-white {
  border-color: white !important;
}

.lg\:order-1 {
  @include media-breakpoint-up(lg) {
    order: 1 !important;
  }
}

.lg\:order-2 {
  @include media-breakpoint-up(lg) {
    order: 2 !important;
  }
}

svg {
  display: inline-block !important;
}

img {
  display: inline-block !important;
}


.lg\:invisible {
  @include media-breakpoint-up(lg) {
    visibility: hidden !important;
  }
}

.z-5 {
  z-index: 5 !important;
}

.post-type-archive-monuments_museums {
  input[type="search"] {
    padding-left: 16px !important;
    background-image: none !important;
  }
}