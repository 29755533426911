.pt-footer {
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        &__buttons {
            padding-top: var(--space-ms);
        }
      }
    .PT-button {
        margin-left: var(--space-sm);
    }
}