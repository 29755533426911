@use "sass:map";

$button-transition: .3s ease-out;

.Chips {
  position: relative;
  appearance: none;
  border: none;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: var(--space-xxs);
  padding: var(--space-tiny) var(--space-xs);
  background: var(--color-primary-100-light);
  color: var(--color-primary-500-light);
  border-radius: 32px;

  &:hover {
    cursor: pointer;
    color: var(--color-primary-700-light);
  }

  &:focus {
    color: var(--color-primary-700-light);
    border: 1px solid var(--color-primary-700-light);
  }


  .tmt-large &,
  &--large {
    grid-column-gap: var(--space-xxs-large);
    padding: var(--space-xxs-large) var(--space-sm-large);

    .Chips__label {
      @include responsive-type(baseline, "large", true);
    }

  }

  &__icon {
    z-index: 0;
  }

  &__label {
    @include responsive-type(baseline, "default", true);
    transform: translateY(1px); // Hack to align font metrics with icon
  }
}
