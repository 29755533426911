@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce-half {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(2px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: 0;
    transform: scale(1.2);
  }

  30% {
    opacity: 0;
    transform: scale(1.2);
  }

  35% {
    opacity: 0.5;
    transform: scale(0.9);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}
